import {
  allManageAppsRoutes,
  allManageAppVersionRoutes,
  allManagePartnersRoutes,
  appVersionPublishFormRoute,
  appVersionPublishRoute,
  manageAppsBaseRoute,
  manageAppVersionBaseRoute,
  managePartnersBaseRoute,
} from '@atlassian/mpac-partners';
import { AtlassianSupportsUkraineRouteHandler } from '@atlassian/mpac-primitives';

import AppBundleDetailRouteHandler from '../pages/appBundles/AppBundleDetailRouteHandler';
import AppBundlesListRouteHandler from '../pages/appBundles/AppBundlesListRouteHandler';
import ArchivedPluginDetailsRouteHandler from '../pages/archivedPluginDetails/ArchivedPluginDetailsRouteHandler';
import NotFoundPage from '../pages/errors/NotFoundPage';
import PlansPageRouteHandler from '../pages/plans/PlansPageRouteHandler';
import { VersionRedirectRoute } from '../pages/versionHistory/RedirectRoute';
import VersionHistoryPageRouteHandler from '../pages/versionHistory/VersionHistoryPageRouteHandler';
import resolveAppListingRoutes from './components/AppListingRoutes/components/AppListingRoutes';
import {
  ManageAppsRouteHandler,
  ManageAppVersionRouteHandler,
  ManagePartnersRouteHandler,
  PublishAppVersionRoutesHandler,
} from './components/PartnerRoutes';
import resolveVendorDetailsRoutes from './components/VendorDetailsRoutes/components/VendorDetailsRoutes';
import { getAetherRoutes } from './getAetherRoutes';
import { StaticRedirectRoute, staticRedirectRoutesMap } from './StaticRedirectRoute';

import type AddonDiscoveryConfig from '../../records/AddonDiscoveryConfig';
import type CollectionsConfig from '../../records/CollectionsConfig';
import type SolutionsConfig from '../../records/SolutionsConfig';
import type VendorDetailsConfig from '../../records/VendorDetailsConfig';
import type { AMKTRoute } from './components/Routes.types';

type GetAllRoutesArgs = {
  addonDiscoveryConfig: AddonDiscoveryConfig;
  collectionsConfig: CollectionsConfig;
  vendorDetailsConfig: VendorDetailsConfig;
  solutionsConfig: SolutionsConfig;
  flags: {
    enableMosaicPartnerProfileExperienceFlag: boolean;
    enableAppBundles: boolean;
  };
};

function getStaticRedirectRoutes(): AMKTRoute {
  return Object.keys(staticRedirectRoutesMap).map((path) => {
    return {
      name: staticRedirectRoutesMap[path].screen,
      path,
      exact: true,
      component: StaticRedirectRoute,
    };
  });
}

function getAppBundlesRoutes(): AMKTRoute[] {
  return [
    {
      name: 'appBundlesListingScreen',
      path: '/app-bundles',
      exact: true,
      component: AppBundlesListRouteHandler,
    },
    {
      name: 'appBundleDetailsScreen',
      path: '/app-bundles/:appBundleId',
      exact: true,
      component: AppBundleDetailRouteHandler,
    },
  ];
}

export default function getAllRoutes({
  vendorDetailsConfig,
  flags: { enableMosaicPartnerProfileExperienceFlag, enableAppBundles },
}: GetAllRoutesArgs): AMKTRoute[] {
  let routes: AMKTRoute[] = [];

  routes = getAetherRoutes();

  if (enableAppBundles) {
    routes = [...routes, ...getAppBundlesRoutes()];
  }

  return [
    ...routes,
    ...resolveVendorDetailsRoutes({
      vendorDetailsConfig,
      enableMosaicPartnerProfileExperienceFlag,
    }),
    ...resolveAppListingRoutes(),
    {
      name: 'managePartnersScreen',
      path: managePartnersBaseRoute,
      component: ManagePartnersRouteHandler,
    },
    {
      name: 'ukraineSupportScreen',
      path: '/atlassian-stands-with-ukraine',
      exact: true,
      component: AtlassianSupportsUkraineRouteHandler,
    },
    {
      name: 'publishNewPrivateAppVersion',
      path: appVersionPublishRoute.path,
      component: PublishAppVersionRoutesHandler,
      exact: true,
    },
    {
      name: 'publishNewPrivateAppVersion',
      path: appVersionPublishFormRoute.path,
      component: PublishAppVersionRoutesHandler,
      exact: true,
    },
    {
      name: 'manageAppVersionScreen',
      path: manageAppVersionBaseRoute,
      component: ManageAppVersionRouteHandler,
    },
    {
      name: 'manageAppsScreen',
      path: manageAppsBaseRoute,
      component: ManageAppsRouteHandler,
    },
    {
      name: 'versionHistoryScreen',
      path: '/apps/:appId(\\d+)/:appSlug([^/]+)?/version-history',
      component: VersionHistoryPageRouteHandler,
      showAetherNavAndFooter: true,
    },
    {
      name: 'versionHistoryScreen',
      path: '/plugins/:appKey([^/]+)/version/:buildNumber',
      component: VersionRedirectRoute,
    },
    {
      name: 'plansScreen',
      path: '/apps/:appId(\\d+)/:appSlug([^/]+)?/plans',
      component: PlansPageRouteHandler,
      showAetherNavAndFooter: false,
    },
    {
      name: 'archivedPluginDetailsScreen',
      path: '/archive/:appId(\\d+)',
      component: ArchivedPluginDetailsRouteHandler,
      showAetherNavAndFooter: true,
    },
    ...getStaticRedirectRoutes(),
    {
      name: 'notFoundScreen',
      // eslint-disable-next-line react/display-name
      render: (routeProps) => {
        return <NotFoundPage {...routeProps} />;
      },
      showAetherNavAndFooter: true,
    },
    ...allManagePartnersRoutes,
    ...allManageAppsRoutes,
    ...allManageAppVersionRoutes,
  ];
}
