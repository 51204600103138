import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../../Routes/components/common/routeHandlers/BaseRouteHandler';

const AppBundleDetailRouteProcessor = lazyForPaint(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "chunk-app-bundle-detail-page" */ './AppBundleDetailPage'
  ).then((c) => c.default)
);

const RouteHandler = (props) => {
  return (
    <BaseRouteHandler scrollToTopOnPathChange includePartnerQuickLinks>
      <LazySuspense fallback={<div />}>
        <AppBundleDetailRouteProcessor {...props} />
      </LazySuspense>
    </BaseRouteHandler>
  );
};

export default RouteHandler;
