import { tryParseJSON } from '@atlassian/mpac-utils';
import { Events, TelemetryErrorType } from '../events';
const CLIENT_SIDE_NETWORK_ERROR_CHROME = 'Failed to fetch';
const CLIENT_SIDE_NETWORK_ERROR_FIREFOX = 'NetworkError when attempting to fetch resource.';
const CLIENT_SIDE_NETWORK_ERROR_OTHER = 'Network request failed';
const CLIENT_CONNECTION_ERROR = 'Connection error';

/**
 * These errors will be thrown by `fetch`, in the following cases (non-exclusively):
 *
 * 1. When the user is offline / has a network fluctuation
 * 2. When the browser cancels the request to due to navigation, or user action
 * 3. Browser throws a CORS error, etc.
 *
 * @see https://fetch.spec.whatwg.org/#fetch-api - for exhaustive list of cases
 *
 * We have no easy way of identifying or categorizing which of these types of errors are actually
 * causing this to be thrown, or how to address them, hence they will be ignored from being
 * counted as "bad" events for SLOs, and tracked separately.
 */
const CLIENT_SIDE_NETWORK_ERRORS = [CLIENT_SIDE_NETWORK_ERROR_CHROME, CLIENT_SIDE_NETWORK_ERROR_FIREFOX, CLIENT_SIDE_NETWORK_ERROR_OTHER];
const isClientSideNetworkError = function isClientSideNetworkError() {
  let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return CLIENT_SIDE_NETWORK_ERRORS.some(errorMessage => message.includes(errorMessage));
};
const isAuthError = apolloError => {
  const serverError = apolloError.networkError;
  return apolloError.message.includes('401') || serverError && (serverError.message.includes('401') || serverError.statusCode === 401);
};
const isIgnorableApolloError = cause => {
  if (cause && typeof cause === 'object' && 'networkError' in cause) {
    var _apolloError$networkE;
    const apolloError = cause;
    return isClientSideNetworkError(apolloError.message) || isClientSideNetworkError((_apolloError$networkE = apolloError.networkError) === null || _apolloError$networkE === void 0 ? void 0 : _apolloError$networkE.message) || isAuthError(apolloError);
  }
  return false;
};

/**
 * Certain types of algolia errors cannot be acted upon by us, eg:
 * - AlgoliaSearchRequestTimeoutError - request times out
 * - AlgoliaSearchUnparsableJSONError - seems to occur when either a VPN or other gateway responds with HTML response, instead of JSON
 * - AlgoliaSearchJSONPScriptErrorError - occurs when the algolia scripts fails to load due to network error
 * - 'Connection error' - network error on client side
 *
 * These types of errors will not be reported as SLO failures.
 */
export const isIgnorableAlgoliaFailure = error => {
  var _parsedError;
  let errorName = error === null || error === void 0 ? void 0 : error.name;
  if (error && typeof error === 'object' && 'networkError' in error) {
    var _apolloError$networkE2;
    const apolloError = error;
    errorName = (_apolloError$networkE2 = apolloError.networkError) === null || _apolloError$networkE2 === void 0 ? void 0 : _apolloError$networkE2.name;
  }
  if (errorName && ['AlgoliaSearchUnparsableJSONError', 'AlgoliaSearchRequestTimeoutError', 'AlgoliaSearchJSONPScriptErrorError', 'AlgoliaSearchUnknownError'].includes(errorName)) {
    return true;
  }
  let parsedError = error; // default value of data

  // if error is a string then try to parse it and create an object
  if (typeof error === 'string') {
    const errorParsed = tryParseJSON(error);
    if (errorParsed.success) {
      parsedError = errorParsed.result;
    }
  }

  // check if its a connection error
  if ((_parsedError = parsedError) !== null && _parsedError !== void 0 && _parsedError.message && typeof parsedError.message === 'string' && parsedError.message.includes(CLIENT_CONNECTION_ERROR)) {
    return true;
  }
  return false;
};
const isIgnoreableError = cause => isIgnorableApolloError(cause) || isIgnorableAlgoliaFailure(cause);
export const isIgnoreableUfoEvent = event => {
  const whitelistedMpacDomains = ['marketplace.atlassian.com.atl.zbizdev.zengel.myshn.net', 'marketplace.stg.internal.atlassian.com', 'marketplace-admin.internal.atlassian.com', 'marketplace-admin.stg.internal.atlassian.com'];
  const currentUrl = window.location.host;
  if (currentUrl.includes('atlassian.com') && !whitelistedMpacDomains.includes(currentUrl)) {
    return true;
  }
  if (event.type === Events.LABEL_UFO_INTERACTION_ERROR && (event.error.errorDetail === TelemetryErrorType.Ignore || isIgnoreableError(event.error.errorDetail || event.error.errorMessage))) {
    return true;
  }
  return false;
};
export const isIgnorableGenericTaskSLOEvent = event => {
  var _cause$cause;
  const {
    cause,
    successful
  } = event;
  const nestedCause = (_cause$cause = cause === null || cause === void 0 ? void 0 : cause.cause) !== null && _cause$cause !== void 0 ? _cause$cause : null;
  if (!successful && (isIgnoreableError(cause) || isIgnoreableError(nestedCause))) {
    return true;
  }
  return false;
};