import CrossIcon from '@atlaskit/icon/glyph/cross';
import MenuIcon from '@atlaskit/icon/glyph/menu';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import {
  breakpointValues,
  FeedbackCollectorDialog,
  useFeedbackCollector,
  useResponsiveBreakpoint,
} from '@atlassian/mpac-primitives';
import { ExternalLink, useLocation } from '@atlassian/mpac-ui';
import { isAppListingLink, UrlUtils } from '@atlassian/mpac-utils';
import { css } from '@compiled/react';
import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';

import { isBranchDeployment } from '../../../../common/utils/branchDeploy';
import BootstrapConfig from '../../../bootstrapConfig';
import { useResolvedCurrentUser } from '../../../context/CurrentUserContext';
import { useGlobalNavAnalytics } from './Analytics/useGlobalNavAnalytics';
import { BranchDeploymentIndicator } from './BranchDeploymentIndicator';
import { MenuBackButton, Search, SignIn } from './Buttons';
import { HelpDropdown, UserDropdown } from './Dropdown';
import {
  ExpandableMenuDesktop,
  expandableMenuLabel,
  ExpandableMenuMobile,
  ExpandableMenuType,
  useExpandableMenuState,
} from './ExpandableMenu';
import { getLinksFromUserResponse } from './getLinksFromUserResponse';
import {
  ActionButton,
  ActionButtonsContainer,
  Container,
  LogoAndMenuContainer,
  MenuButtonsContainer,
  NavbarBackground,
  NavbarContainer,
  VerticalRule,
} from './GlobalNav.styles';
import { MarketplaceLogo } from './MarketplaceLogo';
import { PartnerQuicklinks } from './PartnerQuicklinks';
import { useDesktopKeyboardNavigation } from './useDesktopKeyboardNavigation';

import type { GlobalNavInnerProps, GlobalNavProps } from './types';
import type { UserProps } from './userTypes';

const feedbackTitleDetailsStyle = css({
  fontSize: '1rem',
  marginBottom: token('space.300', '24px'),
  lineHeight: '1.25rem',
});

export const DesktopGlobalNav = ({
  openMenu,
  closeMenu,
  isMenuOpen,
  openedMenu,
  history,
  showSearchIcon = true,
  userProps,
  openFeedbackDialog,
}: GlobalNavInnerProps) => {
  const { expandableMenuRef } = useDesktopKeyboardNavigation({
    openedMenu,
    isMenuOpen,
    closeMenu,
  });
  const { loggedInUser } = useResolvedCurrentUser({ userProps });

  return (
    <div>
      <NavbarBackground>
        <NavbarContainer>
          <LogoAndMenuContainer>
            {isBranchDeployment() && <BranchDeploymentIndicator />}
            <MarketplaceLogo history={history} closeMenu={closeMenu} />
            <MenuButtonsContainer role="navigation" aria-label="Primary">
              <ExpandableMenuDesktop
                ref={expandableMenuRef}
                openMenu={openMenu}
                userProps={userProps}
                isOpen={isMenuOpen}
                openedMenu={openedMenu}
                closeMenu={closeMenu}
                openFeedbackDialog={openFeedbackDialog}
              />
            </MenuButtonsContainer>
          </LogoAndMenuContainer>
          <ActionButtonsContainer role="navigation" aria-label="Secondary">
            {showSearchIcon && <Search onClick={closeMenu} />}
            <HelpDropdown openFeedbackDialog={openFeedbackDialog} onOpen={closeMenu} />
            <VerticalRule />
            {loggedInUser ? (
              <UserDropdown userProps={userProps} onOpen={closeMenu} />
            ) : (
              <SignIn userProps={userProps} onClick={closeMenu} />
            )}
          </ActionButtonsContainer>
        </NavbarContainer>
      </NavbarBackground>
    </div>
  );
};

export const MobileGlobalNav = ({
  openMenu,
  closeMenu,
  isMenuOpen,
  openedMenu,
  history,
  showSearchIcon = true,
  userProps,
  openFeedbackDialog,
}: GlobalNavInnerProps) => {
  const showBackButton = isMenuOpen && openedMenu && openedMenu !== ExpandableMenuType.MOBILE_VIEW;

  return (
    <Container>
      <NavbarBackground>
        <NavbarContainer>
          {showBackButton ? (
            <ActionButtonsContainer>
              <MenuBackButton
                onClick={openMenu(ExpandableMenuType.MOBILE_VIEW)}
                label={expandableMenuLabel[openedMenu]}
              />
            </ActionButtonsContainer>
          ) : (
            <LogoAndMenuContainer>
              {isBranchDeployment() && <BranchDeploymentIndicator />}
              <MarketplaceLogo history={history} closeMenu={closeMenu} />
            </LogoAndMenuContainer>
          )}
          <ActionButtonsContainer>
            {!showBackButton && showSearchIcon && <Search />}
            {isMenuOpen ? (
              <Tooltip content="Back to Navigation">
                <ActionButton onClick={closeMenu} tabIndex={0}>
                  <CrossIcon label="Close" size="large" />
                </ActionButton>
              </Tooltip>
            ) : (
              <ActionButton onClick={openMenu(ExpandableMenuType.MOBILE_VIEW)} tabIndex={0}>
                <MenuIcon label="Menu" size="large" />
              </ActionButton>
            )}
          </ActionButtonsContainer>
        </NavbarContainer>
      </NavbarBackground>
      <ExpandableMenuMobile
        isOpen={isMenuOpen}
        openedMenu={openedMenu}
        openMenu={openMenu}
        userProps={userProps}
        closeMenu={closeMenu}
        openFeedbackDialog={openFeedbackDialog}
      />
    </Container>
  );
};

export const GlobalNav = ({
  history,
  userResponse,
  onSwitchAccount,
  onLogout,
  showSearchIcon = true,
  showPartnerLinks = true,
}: GlobalNavProps) => {
  const { openMenu, closeMenu, isMenuOpen, openedMenu } = useExpandableMenuState();
  const { value: breakpoint } = useResponsiveBreakpoint();
  const isMobile = breakpoint < breakpointValues.md;
  const location = useLocation();
  const isAppListingPage = isAppListingLink(location.pathname || '');

  const { feedbackDialogVisible, openFeedbackDialog, onSubmit, onClose } = useFeedbackCollector({});
  const { fireFeedbackCollectorClickEvent } = useGlobalNavAnalytics();
  const { feedbackCollectorApiKeys, feedbackGatewayBaseUrl } = BootstrapConfig.get();

  const userProps: UserProps = {
    isUserLoading: Boolean(userResponse.loading),
    userLinks: getLinksFromUserResponse(userResponse.data),
    user: userResponse.data?._embedded?.user,
    lastVisited: userResponse.data?._embedded?.lastVisited,
    onSwitchAccount,
    onLogout,
  };

  const { loggedInUser } = useResolvedCurrentUser({ userProps });

  const { vendorId, name } = loggedInUser?.lastVisitedDeveloperSpace || {};

  useEffect(() => {
    /* Close the menu when switching from mobile to desktop view if ExpandableMenuType.MOBILE_VIEW is open, as it's intended only for mobile use. */
    if (!isMobile && isMenuOpen && openedMenu === ExpandableMenuType.MOBILE_VIEW) {
      closeMenu();
    }
  }, [isMobile, openedMenu, isMenuOpen]);

  const showFeedbackDialog = () => {
    if (isMenuOpen && openedMenu !== ExpandableMenuType.MOBILE_VIEW) {
      closeMenu();
    }
    fireFeedbackCollectorClickEvent();
    openFeedbackDialog();
  };

  const appListingReviewTabLink = DOMPurify.sanitize(
    UrlUtils.updateQueryParam(window.location.href, 'tab', 'reviews')
  );

  const feedbackTitleDetails = (
    <p css={[feedbackTitleDetailsStyle]}>
      We are eager to hear your opinion. For any specific feedback on the apps, leave a review{' '}
      <ExternalLink href={appListingReviewTabLink}>here</ExternalLink>.
    </p>
  );

  return (
    <Container aria-label="Header">
      {vendorId && name && showPartnerLinks && (
        <PartnerQuicklinks
          lastVisitedPartnerName={name}
          lastVisitedPartnerId={parseInt(vendorId)}
        />
      )}
      {isMobile ? (
        <MobileGlobalNav
          openMenu={openMenu}
          closeMenu={closeMenu}
          isMenuOpen={isMenuOpen}
          openedMenu={openedMenu}
          history={history}
          userProps={userProps}
          showSearchIcon={showSearchIcon}
          openFeedbackDialog={showFeedbackDialog}
        />
      ) : (
        <DesktopGlobalNav
          openMenu={openMenu}
          closeMenu={closeMenu}
          isMenuOpen={isMenuOpen}
          openedMenu={openedMenu}
          history={history}
          showSearchIcon={showSearchIcon}
          userProps={userProps}
          openFeedbackDialog={showFeedbackDialog}
        />
      )}

      <FeedbackCollectorDialog
        userId={loggedInUser?.id}
        name={loggedInUser?.name}
        entrypointId={feedbackCollectorApiKeys.generalFeedback.entrypointId}
        feedbackGatewayBaseUrl={feedbackGatewayBaseUrl}
        feedbackDialogVisible={feedbackDialogVisible}
        feedbackTitle="Tell us about your experience"
        onClose={onClose}
        onSubmit={onSubmit}
        showTypeField={false}
        customTextAreaLabel="Describe your Atlassian Marketplace experience"
        {...(isAppListingPage && { feedbackTitleDetails })}
      />
    </Container>
  );
};
