import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import FeatureGates, { FeatureGateEnvironment, PerimeterType } from '@atlaskit/feature-gate-js-client';
export { FeatureGateEnvironment as StatsigEnvironment };
export class StatsigClient {
  constructor() {
    var _this = this;
    _defineProperty(this, "isStatsigInitialized", false);
    _defineProperty(this, "evaluatedFlags", {});
    _defineProperty(this, "evaluatedExperiments", {});
    _defineProperty(this, "source", undefined);
    _defineProperty(this, "customAttributes", void 0);
    _defineProperty(this, "clientOptions", void 0);
    _defineProperty(this, "identifers", void 0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _defineProperty(this, "overrides", {});
    _defineProperty(this, "getExperimentValue", function (experimentName, propertyName) {
      let defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let options = arguments.length > 3 ? arguments[3] : undefined;
      if (!_this.isStatsigInitialized) {
        console.warn('Statsig client not initialized.');
        return defaultValue;
      }
      if (_this.overrides[experimentName] !== undefined && _this.overrides[experimentName][propertyName] !== undefined) {
        return _this.overrides[experimentName][propertyName];
      }
      const evaluatedValue = FeatureGates.getExperimentValue(experimentName, propertyName, defaultValue, options);
      _this.evaluatedExperiments = _objectSpread(_objectSpread({}, _this.evaluatedExperiments), {}, {
        [experimentName]: _objectSpread(_objectSpread({}, _this.evaluatedExperiments[experimentName]), {}, {
          [propertyName]: evaluatedValue
        })
      });
      return evaluatedValue;
    });
    _defineProperty(this, "checkGate", function (gateName) {
      let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!_this.isStatsigInitialized) {
        console.warn('Statsig client not initialized.');
        return defaultValue;
      }
      if (_this.overrides[gateName] !== undefined) {
        return Boolean(_this.overrides[gateName]);
      }
      const evaluatedValue = FeatureGates.checkGate(gateName);
      _this.evaluatedFlags[gateName] = evaluatedValue;
      return evaluatedValue;
    });
    _defineProperty(this, "initializeStatsigClientWithValues", async (_ref, source) => {
      let {
        marketplaceAnonymousId,
        environment,
        targetApp,
        clientSdkKey,
        experimentValues,
        customAttributes
      } = _ref;
      this.source = source;
      await FeatureGates.initializeFromValues({
        environment,
        targetApp,
        sdkKey: clientSdkKey
      }, {
        marketplaceAnonymousId
      }, customAttributes, experimentValues);
      this.isStatsigInitialized = true;
    });
    _defineProperty(this, "updateIdentifiers", async overrideIdentifiers => {
      if (!this.isStatsigInitialized || !this.clientOptions) {
        console.warn('Statsig client not initialized.');
        return;
      }
      const {
        environment,
        apiKey,
        targetApp
      } = this.clientOptions;
      this.identifers = _objectSpread(_objectSpread({}, this.identifers), overrideIdentifiers);
      try {
        await FeatureGates.updateUser({
          environment,
          apiKey,
          targetApp,
          // this is optional in FeatureGates.initialize but required for FeatureGates.updateUser
          // COMMERCIAL is the default value for perimeter if we don't provide one
          perimeter: PerimeterType.COMMERCIAL
        }, this.identifers, this.customAttributes);
      } catch (err) {
        console.error('Failed to update identifiers.', err);
      }
    });
    _defineProperty(this, "setOverride", overrideValues => {
      this.overrides = overrideValues;
    });
    _defineProperty(this, "getEvaluatedFlags", () => {
      return this.evaluatedFlags;
    });
    _defineProperty(this, "getEvaluatedExperiments", () => {
      return this.evaluatedExperiments;
    });
    // ---------------------- Experiments EMCEE-------------------------
    /**
     * discussion: https://atlassian.enterprise.slack.com/archives/C04PR2YE4UC/p1725317404567309?thread_ts=1725277152.022099&channel=C04PR2YE4UC&message_ts=1725317404.567309
     * suggests that the exposures from the other target apps will cause issues, and recommends either suppressing the experiment or adding an in-code check
     **/
    // ---------------------- Experiments MPAC-------------------------
    _defineProperty(this, "enableNlpSearch", function () {
      let {
        fireExperimentExposure = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (_this.source === 'PRODUCT') {
        return false;
      }
      if (_this.source === 'MPAC') {
        return _this.getExperimentValue('enable_mpac_nlp_search_experiment', 'isExperienceEnabled', false, {
          fireExperimentExposure
        });
      }
      return false;
    });
    _defineProperty(this, "enableSponsoredAboveExperiment", () => {
      if (this.source === 'PRODUCT') {
        return false;
      }
      if (this.source === 'MPAC') {
        const position = this.getExperimentValue('enable_sponsored_listing_mpac', 'position', 'none');
        return position === 'above';
      }
      return false;
    });
    _defineProperty(this, "enableSponsoredBelowExperiment", () => {
      if (this.source === 'PRODUCT') {
        return false;
      }
      if (this.source === 'MPAC') {
        const position = this.getExperimentValue('enable_sponsored_listing_mpac', 'position', 'none');
        return position === 'below';
      }
      return false;
    });
    _defineProperty(this, "enableOneClickAppInstall", () => {
      if (this.source === 'MPAC') {
        return this.getExperimentValue('enable_one_click_install_mpac', 'isExperienceEnabled', false);
      }
      if (this.source === 'PRODUCT') {
        return this.checkGate('enable_emcee_one_click_install');
      }
      return false;
    });
    // ---------------------- Feature Flags ----------------------
    _defineProperty(this, "enableStaffPickOnAppListing", () => {
      return this.checkGate('enable_staff_pick_on_app_listing');
    });
    _defineProperty(this, "showAnnouncementBannerInResourcesTab", () => {
      return this.checkGate('show_announcement_banner_in_resources_tab');
    });
    _defineProperty(this, "showCompassApps", () => {
      return this.checkGate('show_compass_apps');
    });
    _defineProperty(this, "showNewJiraSoftwareLabel", () => {
      return this.checkGate('show_new_jira_software_label');
    });
    _defineProperty(this, "enableMosaicPartnerProfileExperience", () => {
      return this.checkGate('enable_mosaic_partner_profile_experience');
    });
    _defineProperty(this, "showChangemakersCollectionTile", () => {
      return this.checkGate('show_changemakers_collection_tile');
    });
    _defineProperty(this, "enableCurrentUserApiViaStoreBff", () => {
      return this.checkGate('enable_current_user_api_via_store_bff');
    });
    _defineProperty(this, "enableGeoIPFromStoreBff", () => {
      return this.checkGate('enable_geoip_from_store_bff', true);
    });
    _defineProperty(this, "enableMediaFromProductListing", () => {
      return this.checkGate('enable_media_from_product_listing');
    });
    _defineProperty(this, "enableAppBundles", () => {
      return this.checkGate('enable_app_bundles');
    });
    _defineProperty(this, "enableTeamsWithMakerIntegration", () => {
      return this.checkGate('partner_teams_tab_maker_integration');
    });
    _defineProperty(this, "displayAuditTabInManagePartnerPage", () => {
      return this.checkGate('display_audit_tab_in_manage_partner_page');
    });
    _defineProperty(this, "enableAlgoliaCallOnAppListingAndDialogs", () => {
      return this.checkGate('enable_algolia_call_on_applising_and_dialogs');
    });
    _defineProperty(this, "enableEditionsGA", () => {
      return this.checkGate('enable_partner_app_edition_tab');
    });
    _defineProperty(this, "enableScalingLogicOnUI", () => {
      return this.checkGate('enable_scaling_logic_on_ui');
    });
    _defineProperty(this, "enablePartnerUserApiViaConsoleBffGql", () => {
      return this.checkGate('enable_partner_user_api_via_console_bff_gql');
    });
    _defineProperty(this, "enablePartnerPaymentsViaConsoleBff", () => {
      return this.checkGate('enable_partner_payments_via_console_bff');
    });
    _defineProperty(this, "partnersNavigationRefresh", () => {
      return this.checkGate('partners_navigation_refresh');
    });
    _defineProperty(this, "enableForgeRemoteDataResidencyChanges", () => this.checkGate('forge_remote_data_residency_changes'));
    _defineProperty(this, "enableRunsOnAtlassian", () => {
      if (this.source === 'PRODUCT') {
        return this.checkGate('enable_runs_on_atlassian_emcee');
      }
      if (this.source === 'MPAC') {
        return this.checkGate('runs_on_atlassian_marketplace');
      }
      return false;
    });
    _defineProperty(this, "showUpdatedPartnerTierMessage", () => this.checkGate('partner_tier_messaging_update'));
    // ---------------------- Permanent Feature Flags ----------------------
    _defineProperty(this, "showAppListingTrustTab", () => {
      // https://switcheroo.atlassian.com/ui/gates/1301e2c9-053a-4d13-9697-b06d84d481bf
      return this.checkGate('show_applisting_trust_tab');
    });
    _defineProperty(this, "enableDcMarketplaceDownloadSignature", () => {
      return this.checkGate('enable-dc-marketplace-download-signature');
    });
    _defineProperty(this, "showRevampedCloudPricingTab", () => this.checkGate('show_revamped_cloud_pricing_tab'));
    _defineProperty(this, "enable150KTierSupport", () => this.checkGate('enable_150k_tier_support'));
    _defineProperty(this, "fetchHostProductPricingFromConsoleBff", () => this.checkGate('fetch_parent_product_pricing_from_console_bff'));
    _defineProperty(this, "enableCustomer150kTierSupport", () => this.checkGate('enable_customer_150k_tiers'));
    _defineProperty(this, "showEditionsAnnouncementAlert", () => this.checkGate('show_editions_announcement_alert'));
    _defineProperty(this, "enableSelfServeAppEditions", () => this.checkGate('enable_self_serve_app_editions_view'));
    _defineProperty(this, "enableMILsupport", () => this.checkGate('enable_mil_support'));
    // https://switcheroo.atlassian.com/ui/gates/47da0e52-7689-4b69-9f5d-435dc1f32cd0/key/enable_create_urm_req_for_maketplace_app_req
    _defineProperty(this, "enableCreateUrmRequestForMarketplaceAppRequest", () => this.checkGate('enable_create_urm_req_for_maketplace_app_req'));
    _defineProperty(this, "enableMILReportingSupport", () => this.checkGate('enable_mil_reporting_support'));
    _defineProperty(this, "enableAppEditionM3ReportingSupport", () => this.checkGate('enable_app_edition_m3_reporting_support'));
  }
  async initWithStatsigGlobalInstance(source) {
    // for jira and connie the statsig client is already initialized globally
    // we cannot pass the custom attributes as statsig is initialized in their backend
    this.isStatsigInitialized = true;
    this.source = source;
  }

  // IMPORTANT: Calling this method will completely re-initialize the client with a new set of flags. You will need to re-render the entire page after this completes to ensure everything picks up the new flag values. You should avoid using this frequently as it has implications on the user experience.
  async initializeStatsigClient(_ref2) {
    let {
      clientKey,
      environment,
      targetApp,
      marketplaceAnonymousId,
      tenantId,
      atlassianAccountId
    } = _ref2;
    let customAttributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let source = arguments.length > 2 ? arguments[2] : undefined;
    this.source = source;
    const clientOptions = {
      apiKey: clientKey,
      environment,
      targetApp,
      fetchTimeoutMs: 5000
    };
    const identifiers = {
      marketplaceAnonymousId,
      tenantId,
      atlassianAccountId
    };
    try {
      await FeatureGates.initialize(clientOptions, identifiers, customAttributes);
      this.isStatsigInitialized = true;
      this.identifers = identifiers;
      this.clientOptions = clientOptions;
      this.customAttributes = customAttributes;
    } catch (err) {
      console.error('Failed to initialize FeatureGates client.', err);
    }
  }
}
export default StatsigClient;