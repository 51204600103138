import { Meta, SkipToContent } from '@atlassian/mpac-primitives';
import Telemetry from '@atlassian/mpac-telemetry';
import { UrlUtils } from '@atlassian/mpac-utils';
import ImmutablePropTypes from '@atlassian/react-immutable-proptypes';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';
import React, { Component } from 'react';
import { matchPath } from 'react-router-dom';

import type AddonDiscoveryConfig from 'app/records/AddonDiscoveryConfig';
import type CollectionsConfig from 'app/records/CollectionsConfig';
import type CommonConfig from 'app/records/CommonConfig';
import type SolutionsConfig from 'app/records/SolutionsConfig';
import CollectionsFooter, { LegacyFooter } from '../../../../../../common/components/Footer';
import MaintenanceBanner from '../../../../../../common/components/MaintenanceBanner';
import BootstrapConfig from '../../../../../bootstrapConfig';
import CommonPropTypes from '../../../../../constants/PropTypes';
import { Footer as GlobalFooter } from '../../../../common/Footer';
import { GlobalNav } from '../../../../common/GlobalNav/GlobalNav';
import SubscribeMailingListDialog from '../../../../common/SubscribeMailingListDialog';
import { getRouteByName, resolveCurrentRoute } from '../../../utils/RouteUtils';
import GeneralTextSearch from './GeneralTextSearch';
import Header from './Header';

import type { UserResponse } from '../../../../common/GlobalNav/userTypes';
import type { History } from 'history';
import type { ReactNode } from 'react';

// Must be an actual URL - data URIs don't cut it
import AtlassianLogoCompactBlue from '../../../../../assets/images/AtlassianLogoCompactBlue.png';

const DEFAULT_DESCRIPTION =
  'Browse the top apps, add-ons, plugins & integrations for Jira, Confluence, Bitbucket & other Atlassian products. ' +
  'Free 30-day trial for all apps.';

type RouteContainerPropTypes = {
  addonDiscoveryConfig: AddonDiscoveryConfig;
  collectionsConfig: CollectionsConfig;
  solutionsConfig: SolutionsConfig;
  children: ReactNode;
  displayGeneralTextSearch: boolean;
  displayPartnerQuickLinks: boolean;
  history: History;
  location: {
    pathname: string;
    search: string;
  };
  getUserData: (...args) => void;
  onRouteChange: (...args) => void;
  onLogout: (...args) => void;
  openMailingListSubscribeDialog: (...args) => void;
  user: Map<any, any>;
  commonConfig: CommonConfig;
  allRoutes: [];
  onSwitchAccount: (...args) => void;
  displayCollectionsFooter?: boolean;
  enableCurrentUserApiViaStoreBff: boolean;
};

class RouteContainer extends Component<RouteContainerPropTypes> {
  static propTypes = {
    addonDiscoveryConfig: CommonPropTypes.addonDiscoveryConfig.isRequired,
    collectionsConfig: CommonPropTypes.collectionsConfig.isRequired,
    solutionsConfig: CommonPropTypes.solutionsConfig.isRequired,
    children: PropTypes.node.isRequired,
    displayGeneralTextSearch: PropTypes.bool.isRequired,
    displayPartnerQuickLinks: PropTypes.bool.isRequired,
    history: CommonPropTypes.history.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
    getUserData: PropTypes.func.isRequired,
    onRouteChange: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    openMailingListSubscribeDialog: PropTypes.func.isRequired,
    user: ImmutablePropTypes.map.isRequired,
    commonConfig: CommonPropTypes.commonConfig.isRequired,
    allRoutes: PropTypes.array.isRequired,
    onSwitchAccount: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const query = UrlUtils.resolveImmutableQuery(props.location.search);
    if (query.get('newsletterSignup')) {
      props.history.replace({
        pathname: props.location.pathname,
        search: UrlUtils.resolveSearchFromImmutableQuery(query.delete('newsletterSignup')),
      });
      props.openMailingListSubscribeDialog();
    }
  }

  getMatchedRoute = (pathname) => resolveCurrentRoute(this.props.allRoutes, pathname);

  getMatchedRouteName = (pathname) => {
    const matchedRoute = this.getMatchedRoute(pathname);
    return matchedRoute?.name;
  };

  getFilters(matchedRoute) {
    const matchParams = matchPath(this.props.location.pathname, matchedRoute);
    const queryParams = queryString.parse(this.props.location.search || '');
    const { silter, application, ...restParams } =
      (matchParams?.params as { [key: string]: string }) || {};

    return {
      sort: silter,
      product: application,
      ...restParams,
      ...queryParams,
    };
  }

  componentDidMount() {
    if (!this.props.enableCurrentUserApiViaStoreBff) {
      this.props.getUserData();
    }
    const matchedRoute = this.getMatchedRoute(this.props.location.pathname);
    const filters = this.getFilters(matchedRoute);
    this.props.onRouteChange({
      name: matchedRoute?.name,
      suppressPageView: matchedRoute?.suppressPageView,
      pathname: this.props.location.pathname,
      search: this.props.location.search,
      options: {
        filters,
        additionalAttributes: matchedRoute?.additionalAttributes,
      },
    });

    this.props.history.listen(() => {
      Telemetry.routeChangeStart();
    });
  }

  componentDidUpdate(prevProps) {
    const prevPathname = prevProps.location.pathname || '';
    const prevSearch = prevProps.location.search || '';
    const matchedRoute = this.getMatchedRoute(this.props.location.pathname);
    const filters = this.getFilters(matchedRoute);

    if (
      prevPathname !== this.props.location.pathname ||
      prevSearch !== this.props.location.search
    ) {
      const referrer = prevPathname ? `${window.location.origin}${prevPathname}${prevSearch}` : '';
      this.props.onRouteChange({
        referrer,
        name: this.getMatchedRouteName(this.props.location.pathname),
        suppressPageView: matchedRoute?.suppressPageView,
        pathname: this.props.location.pathname,
        search: this.props.location.search,
        options: {
          filters,
          additionalAttributes: matchedRoute?.additionalAttributes,
        },
      });
    }
  }

  renderFooter({ showAetherNavAndFooter }) {
    if (showAetherNavAndFooter) {
      return <GlobalFooter />;
    }

    return (
      <>
        {this.props.displayCollectionsFooter && (
          <CollectionsFooter
            history={this.props.history}
            config={{
              commonConfig: this.props.commonConfig,
              solutionsConfig: this.props.solutionsConfig,
              collectionsConfig: this.props.collectionsConfig,
            }}
          />
        )}
        <LegacyFooter />
      </>
    );
  }

  render() {
    const { mpacBaseUrl } = BootstrapConfig.get();
    const userResponse = this.props.user.toJS() as UserResponse;
    const { showAetherNavAndFooter, hideSearchIconOnAetherNav, hidePartnerLinks } =
      this.getMatchedRoute(this.props.location.pathname) ||
      getRouteByName(this.props.allRoutes, 'notFoundScreen'); // will run and display the NotFound page if no other matching route is discovered

    return (
      <div>
        <MaintenanceBanner pagePath={this.props.location.pathname} />
        <Meta
          canonicalUrl={mpacBaseUrl + this.props.location.pathname}
          description={DEFAULT_DESCRIPTION}
          image={AtlassianLogoCompactBlue}
        />
        <SubscribeMailingListDialog />
        {showAetherNavAndFooter ? (
          <>
            <SkipToContent />
            <GlobalNav
              history={this.props.history}
              userResponse={userResponse}
              onSwitchAccount={this.props.onSwitchAccount}
              onLogout={this.props.onLogout}
              showSearchIcon={!hideSearchIconOnAetherNav}
              showPartnerLinks={!hidePartnerLinks}
            />
          </>
        ) : (
          <Header
            onLogout={this.props.onLogout}
            userData={this.props.user}
            history={this.props.history}
            displayPartnerQuickLinks={this.props.displayPartnerQuickLinks}
            onSwitchAccount={this.props.onSwitchAccount}
          >
            {this.props.displayGeneralTextSearch && (
              <div className="text-search-container">
                <GeneralTextSearch />
              </div>
            )}
          </Header>
        )}
        {this.props.children}
        {this.renderFooter({ showAetherNavAndFooter })}
      </div>
    );
  }

  onSubmitGeneralSearch = ({ query }) => {
    this.props.history.push({
      pathname: this.props.addonDiscoveryConfig.links.search.href,
      search: UrlUtils.resolveSearchFromImmutableQuery(Map({ query })),
    });
  };
}

export default RouteContainer;
